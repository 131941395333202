@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 979px) {
}

@media screen and (max-width: 764px) {
}

@media (max-width: 767px) {
  .pdf_container .container {
    padding: 0;
  }

  .pdf_container canvas {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .recommended_slider .swiper-wrapper {
    /* transform:unset!important; */
  }

  .recommended_slider .swiper-slide {
    width: 100% !important;
    flex: 0 0 80%;
    max-width: 72%;
  }
  .group { width: 48%;}
  .middle-container { height: calc(100vh - 170px);}
  .order-summary .middle-container { height: calc(100vh - 100px); overflow: auto;}
  .order-summary .middle-container.scrollUpTwo { height: calc(100vh - 0px);}
  /* .order-summary .btm-buttons { position: absolute; width:100%; bottom:0%;} */

  /* .cart_sidebar { height:calc(100vh - 30px);} */
  .cart_wrapper .hotel_name { padding-bottom: 10px;}
  /* .ios-device .order-summary .btm-buttons { position: absolute; width:100%; bottom:25px;} */
  .ios-device .order-summary .middle-container { height: calc(100vh - 175px);}
  #root.ios-device .middle-container.scrollUpTwo { height: calc(100vh - 148px); top: 68px; position: relative;}

  /* .ios-device .order-summary .middle-container { height: calc(100vh - 175px);} */
  /* .accordion { max-height: 40vh; overflow: auto;} */
  /* .ios-device .cart_sidebar { height:calc(100vh - 50px);} */
  #root.ios-device .toolbar-vh .middle-container { height: calc(100vh - 95px);}
  #root.ios-device .toolbar-vh .middle-container.scrollUpTwo { height: calc(100vh - 68px); top: 68px; position: relative;}
  
  #root.ios-device .acordion-wrap  { height: calc(100vh - 270px);}
  #root.ios-device .toolbar-vh .acordion-wrap  { height: calc(100vh - 200px);}
  .sm-box small { font-size: 12px;}
  .hasAnotherSlide.white-bg:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100vh;
    background: #f5f6f7;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
}

.cart_sidebar.payment-con header { max-height:130px;}
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 374px) {
}
